import { styled } from "styled-components";
import { Colors, Media } from "../../Contstants";

export const NotificationsPopup = styled.div`
  width: 380px;
  border-radius: 16px;
  border: 1px solid #dee3e9;
  background: #fff;
  box-shadow: 0px 3px 5px 0px rgba(154, 154, 154, 0.25);
  position: absolute;
  top: 30px;
  left: 0px;
  z-index: 9;
  overflow: hidden;
  padding-left: 24px;

  @media (max-width: ${Media.mobile}) {
    max-width: 310px;
    text-align: left;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 16px 0px;

    span {
      margin: 0 !important;
    }
  }

  .list {
    padding-bottom: 14px;
    border-bottom: 1px solid #ececec;
    margin-bottom: 22px;

    img {
      margin-right: 8px;
      float: left;
      width: 28px;
    }

    p {
      margin: 0;
      color: ${Colors.textDark};

      svg {
        font-size: 22px;
        margin-right: 8px;
        float: left;
        width: 28px;
      }
    }
  }

  p.big {
    color: ${Colors.textDark};
    margin: 0 0 16px 0;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
  }

  .header span,
  .small {
    color: #8e99a4;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin: 0 0 22px 0;
    margin-top: 10px;
  }
`;

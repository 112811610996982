import { ReactNode, useRef } from "react";
import styled from "styled-components";
import { Colors } from "../../Contstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  isVisible: boolean;
  children?: ReactNode;
  onClose: () => void;
  onSuccess?: () => void;
  description?: string;
  isErrorCircleVisible: boolean;
  closeButtonIsHidden?: boolean;
  headerTextDescription?: string;
}

const ModalV3 = ({
  isVisible,
  children,
  onClose,
  onSuccess,
  description,
  isErrorCircleVisible,
  closeButtonIsHidden,
  headerTextDescription,
}: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <MainContainer
      onClick={(e) => {
        if (containerRef.current === e.target) {
          onClose();
        }
      }}
      $isVisible={isVisible}
      ref={containerRef}
    >
      <ModalSection>
        {isErrorCircleVisible ? (
          <Circle>
            <FontAwesomeIcon
              color="white"
              width={18}
              height={18}
              icon={faXmark}
            />
          </Circle>
        ) : (
          <HeaderText>{headerTextDescription}</HeaderText>
        )}
        <ModalDescription>{description}</ModalDescription>
        {closeButtonIsHidden ? null : (
          <CloseButton onClick={onClose} type="button">
            Close
          </CloseButton>
        )}
        {children}
      </ModalSection>
    </MainContainer>
  );
};

export default ModalV3;

const MainContainer = styled.div<{ $isVisible: boolean }>`
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: ${(props) => (props.$isVisible ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalSection = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 4px 4px 20px 0px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
`;

const HeaderText = styled.h1`
  color: var(--Cranberry-100, #ee505a);
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
`;

const Circle = styled.div`
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  background-color: ${Colors.red};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalDescription = styled.p`
  color: #101828;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  max-width: 380px;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  display: flex;
  width: 252px;
  height: 40px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background-color: ${Colors.newBlue};
  border-radius: 8px;
  box-shadow:
    0px 2px 5px 0px rgba(60, 66, 87, 0.08),
    0px 1px 1px 0px rgba(0, 0, 0, 0.12);
  border: none;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
`;

import { styled } from "styled-components";
import { Colors, Media } from "../../Contstants";

export const Sidebar = styled.div`
  padding-right: 13px;
  border-right: 1px solid #e4e4e4;
  width: 150px;

  p {
    color: rgba(43, 45, 51, 0.5);
    font-size: 14px;
    padding: 8px 4px;
    margin-bottom: 16px;
    transition: all 0.3s ease;
    cursor: pointer;
    margin-top: 0;

    svg {
      margin-right: 8px;
    }

    &.active,
    &:hover {
      border-radius: 8px;
      color: #fff;
      width: 100%;
      padding: 8px 16px;
      background-color: ${Colors.newBlue};
    }
  }
`;

export const ProfileContainer = styled.div`
  display: none;
  @media (width >= 768px) {
    display: flex;
    margin: 40px 20px;
    min-height: 50vh;
  }
`;

export const ContentLayout = styled.div`
  padding-left: 27px;
  flex: 1;
`;

export const TabHeaderContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 10px;
  margin-bottom: 48px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 3;
  background: #f4f6f8;
  align-items: center;

  h3 {
    color: #051123;
    margin: 0 0 4px 0;
    font-size: 20px;
    font-family: "Inter";

    svg {
      margin-left: 8px;
    }
  }

  p {
    margin: 0;
    color: #8f9195;
    font-size: 12px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  @media (max-width: ${Media.mobile}) {
    display: none;
  }
`;

export const NotificationsSwitcher = styled.div`
  display: flex;
  gap: 20;

  h3 {
    color: #051123;
    font-size: 16px;
    margin: 0 0 4px 0;
  }

  p {
    color: #8f9195;
    font-size: 12px;
    margin: 0;
  }

  & > div {
    flex: 1;

    & > div {
      display: flex;
      margin-top: 12px;

      &.active {
        .react-switch-bg {
          background: #bcbcbc !important;
        }
      }

      span {
        margin-left: 12px;
        color: #051123;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  @media (max-width: ${Media.mobile}) {
    flex-direction: column;
    justify-content: center;
    width: max-content;
    margin: 25px 0;

    & > div {
      flex: 1;
      padding-bottom: 25px;
    }
  }
`;

export const AvatarInfoCnt = styled.div`
  display: block;
  align-items: center;
  gap: 20px;
  margin-bottom: 24px;

  & > div:nth-child(1) {
    display: flex;
    gap: 20px;
  }

  svg {
    width: 120px;
    height: 120px;
    color: ${Colors.dark};
  }
  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: block;
    object-fit: cover;
    object-position: center;
  }
  @media (max-width: ${Media.mobile}) {
    justify-content: center;
    margin: 25px 0;

    & > div:nth-child(1) {
      align-items: center;
    }
  }
`;
export const AvatarBox = styled.div`
  div:nth-child(1) {
    color: #051123;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    margin-top: 5px;
  }
  div:nth-child(2),
  div:nth-child(3) {
    color: rgba(0, 0, 0, 0.5);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 2px;
  }

  @media (max-width: ${Media.mobile}) {
    div:nth-child(1) {
      margin-top: 0;
    }
  }
`;

export const FlexBtnCnt = styled.div`
  margin-top: -35px;
  display: flex;
  gap: 16px;
  margin-left: 140px;

  button {
    line-height: 20px;
    font-weight: bold;
  }

  @media (max-width: ${Media.mobile}) {
    margin-left: 0;
    margin-top: 15px;
  }
`;

export const PersonCnt = styled.div`
  margin-bottom: 25px;
  &.reset {
    & > div div:nth-child(1) {
      position: unset;
      margin-top: 25px;
      margin-bottom: 0;
    }
  }

  @media (min-width: ${Media.mobile}) {
    margin-bottom: 15px;
    input {
      min-width: 280px;
    }
  }

  .resetPwd {
    display: flex;
    flex-wrap: wrap;
    gap: 25px 0;

    .error {
      top: 80px;
    }

    @media (max-width: ${Media.mobile}) {
      display: block;
      svg {
        bottom: 8px;
      }
    }
  }
  .value {
    min-width: 213px !important;
  }
`;

export const PersonFlex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
  gap: 10px;
  label {
    padding-top: 5px;
  }

  @media (max-width: ${Media.mobile}) {
    display: block;
  }
`;

export const ModalCnt = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;

  & > div:nth-child(1) {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
  }

  .modalBox {
    position: fixed;
    width: 370px;
    background-color: #fff;
    border-radius: 8px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .closeBtn {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      width: 24px;
      height: 24px;
    }

    .sendIcon {
      width: 55px;
      height: 55px;
      display: block;
      margin: 50px auto;
      color: #65c68c;
    }
  }

  .message {
    margin-top: -25px;
    color: #101828;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px; /* 150% */
  }

  @media (max-width: ${Media.mobile}) {
    .message {
      font-size: 16px;
    }
  }
`;

export const NotifactionCnt = styled.div`
  position: relative;
  .cover {
    opacity: 0.2;
    user-select: none;
  }
  .commingSoon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 46px;
    letter-spacing: 10px;
    color: ${Colors.newBlue};
  }
`;

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <FooterContainer>
      <HiddenDiv></HiddenDiv>
      <AllRightsText>
        © 2023 <span>DHC GmbH.</span> All rights reserved
      </AllRightsText>
      <NavSection>
        <Link to={"/imprint"}>{t("footer.impressum")}</Link>
        <Link to={"/privacy-policy"}>{t("footer.privacy_policy")}</Link>
        <Link to={"/terms-of-use"}>{t("footer.terms_of_use")}</Link>
      </NavSection>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
  margin-top: 30px;
  flex-direction: column-reverse;
  @media (width >= 760px) {
    flex-direction: row;
  }
`;

const AllRightsText = styled.p`
  color: #4e6276;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  span {
    color: #013567;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
`;

const NavSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  @media (width >= 760px) {
    flex-direction: row;
    gap: 0;
  }
  a {
    font-size: 14px;
    color: #475467;
    margin-right: 15px;
    display: inline-block;
  }
`;

const HiddenDiv = styled.div`
  width: 20%;
`;

import styled from "styled-components";
import { Colors, Distances, Media } from "../../Contstants";

interface Props {
  $footer?: boolean;
}

interface ImgProp {
  $imgSrc?: string | null;
}

export const HeaderStyled = styled.div<Props>`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: ${Distances.md};
  align-items: center;

  @media (max-width: ${Media.mobile}) {
    padding: 16px 5px;
    display: ${(props: Props) => (props.$footer ? "block" : "flex")};
    text-align: center;

    img {
      margin-bottom: ${(props: Props) => (props.$footer ? "10px" : "0px")};
    }
  }
`;

export const Navs = styled.div`
  margin-left: auto;

  @media (max-width: ${Media.mobile}) {
    text-align: center;

    .contact {
      display: none;
    }
  }

  a {
    color: #475467;
    margin-right: 15px; //34px when notifaction is set to display block
    display: inline-block;

    &.btn {
      margin-left: 6px;
      margin-right: 0;
      font-weight: 500;
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      background: #fff;
      padding: 10px 18px;
      color: ${Colors.newBlue};

      &:hover,
      &.active {
        border-radius: 8px;
        background: rgba(58, 156, 173, 0.22);
        border-color: transparent;
      }
    }
  }
`;

export const Notifications = styled.div`
  display: none;
  width: 40px;
  height: 40px;
  padding: 7px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #ccc;
  position: relative;
  cursor: pointer;

  span {
    position: absolute;
    top: 5px;
    right: 7px;
    background-color: #eb5757;
    font-size: 10px;
    color: #fff;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 12px;

  img {
    width: 36px;
    margin-right: 8px;
    cursor: pointer;
    position: relative;
    top: -3px;
  }
`;

export const Profile = styled.div<ImgProp>`
  width: 40px;
  height: 40px;
  margin-left: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ccc;
  position: relative;
  color: #fff;
  user-select: none;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("${(props) => props.$imgSrc}");

  span {
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 3px solid #fff;
    bottom: -1px;
    right: -1px;
    z-index: 2;
    background: green;
  }
`;

export const LogoImg = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 42px;

  @media (max-width: ${Media.mobile}) {
    height: 28px;
  }
`;

import styled, { css } from "styled-components";
import { Colors, Media } from "../../Contstants";

interface IProps {
  $green?: boolean;
  $tight?: boolean;
  $fullWidth?: boolean;
  $rounded?: boolean;
  $small?: boolean;
  $light?: boolean;
  $fontFamily?: "Poppins" | "Inter";
  $fontWeight?: number;
  $red?: boolean;
  $width?: string;
  disabled?: boolean;
}

export const ButtonBox = styled.button<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props: IProps) => {
    if (props.$red) {
      return Colors.red;
    } else if (props.$green) {
      return Colors.newBlue;
    } else if (props.$light) {
      return "#fff";
    } else {
      return Colors.brand;
    }
  }};
  color: ${(props) => (props.$light ? "#051123" : Colors.white)};
  padding: ${(props) =>
    props.$tight ? "10px 42px" : props.$small ? "8px 20px" : "19px 40px"};
  border-radius: ${(props) => (props.$rounded ? "32px" : "6px")};
  border: none;
  white-space: nowrap;
  position: relative;
  z-index: 2;
  font-family: ${(props) =>
    props.$fontFamily === "Poppins" ? "Poppins" : "Inter"};
  font-size: ${(props) =>
    props.$tight ? "16px" : props.$small ? "12px" : "18px"};
  font-style: normal;
  font-weight: ${(props) => props.$fontWeight ?? (props.$small ? 400 : 700)};
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)}!important;
  width: ${(props) => (props.$width ? props.$width : "auto")};
  ${(props) =>
    props.$light &&
    css`
      border: 1px solid #d0d0d0;
      box-shadow: 0px 3px 5px 0px rgba(154, 154, 154, 0.25);
      padding: 7px 20px;
    `}

  min-width: ${(props) =>
    props.$fullWidth ? "100%" : props.$small ? "auto" : "200px"};

  @media (max-width: ${Media.mobile}) {
    width: 100%;
    min-width: auto;
    padding: ${(props) => (props.$tight ? "8px 16px" : "14px 24px")};
    font-size: 14px;
  }

  img {
    max-height: 13px;
    margin-right: 16px;
  }

  &[disabled],
  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonContainer = styled.div<IProps>`
  position: relative;
  display: inline-block;
  display: flex;
  align-items: end;
  min-width: ${(props) =>
    props.$fullWidth ? "100%" : props.$small ? "auto" : "200px"};

  @media (max-width: ${Media.mobile}) {
    width: 100%;
  }
`;

import { useState } from "react";
import { styled } from "styled-components";
import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

export type SelectOption =
  | {
      id: string;
      title: string;
    }
  | {
      id: string;
      value: string;
    };

type Props<T extends SelectOption> = {
  onChange?: (id: string) => void;
  defaultValue?: string;
  isError?: boolean;
  title: string;
  options: T[] | undefined;
  required?: boolean;
  backgroundColor?: string;
  disabled?: boolean;
};

const SelectDropdown = <T extends SelectOption>({
  onChange,
  defaultValue,
  isError,
  title,
  options,
  required,
  backgroundColor,
  disabled,
}: Props<T>) => {
  const [currentValue, setCurrentValue] = useState(defaultValue || "");

  const handleChange = ({
    target: { value },
  }: SelectChangeEvent<typeof currentValue>) => {
    setCurrentValue(value);
    onChange?.(value);
  };

  return (
    <Container>
      <LabelSection>
        <InputLabel
          id="tasks-select"
          style={{ fontSize: "12px", fontFamily: "Inter", color: "#142A5E" }}
        >
          {title}
        </InputLabel>
        {required ? (
          <FontAwesomeIcon
            icon={faAsterisk}
            width={"7px"}
            color="#f27c83"
            style={{ paddingLeft: "6px" }}
          />
        ) : null}
      </LabelSection>
      <Select
        disabled={disabled}
        labelId="tasks-select"
        style={{
          width: "100%",
          minWidth: "100px",
          overflow: "clip",
          color: "#4E6276",
          backgroundColor: backgroundColor,
        }}
        error={isError}
        onChange={handleChange}
        value={currentValue}
      >
        {options?.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {"value" in item ? item.value : item.title}
          </MenuItem>
        ))}
      </Select>
    </Container>
  );
};

export default SelectDropdown;

const Container = styled.div`
  width: 100%;
`;
const LabelSection = styled.div`
  display: flex;
  flex-direction: row;
`;

import React, { FC } from "react";
import { Container } from "./styled";
import Header from "../Header";
import Footer from "../Footer";
import { styled } from "styled-components";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const Layout: FC<Props> = ({ children }) => {
  return (
    <Wrapper>
      <Container>
        <Header />
        {children}
      </Container>
      <Footer />
    </Wrapper>
  );
};

export default Layout;

const Wrapper = styled.div`
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
`;

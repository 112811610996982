import { FormControlLabel, styled } from "@mui/material";
import { Checkbox as MUICheckbox } from "@mui/material";
import { SyntheticEvent } from "react";

const Checkbox = (props: {
  onChange?: (event: SyntheticEvent<Element, Event>, checked: boolean) => void;
  label?: React.ReactNode;
  defaultValue?: boolean;
  disabled?: boolean;
}) => {
  return (
    <StyledFormControlLabel
      control={
        <MUICheckbox checked={props.defaultValue} disabled={props.disabled} />
      }
      label={props.label}
      onChange={props.onChange}
    />
  );
};

export default Checkbox;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: 0;

  .MuiFormControlLabel-label {
    font-family: Inter;
    color: rgb(78, 98, 118);
  }
`;

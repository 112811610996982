import { UseMutationOptions, useMutation } from "react-query";
import axiosInstance from "../../utils/axios";

function deleteUser(id: string) {
  return axiosInstance.delete(`practitioner/${id}`);
}

export function useDeletePractitionerMutation(
  options?: Omit<
    UseMutationOptions<unknown, unknown, string, unknown>,
    "mutationFn"
  >,
) {
  return useMutation<unknown, unknown, string>(deleteUser, options);
}

import { FC } from "react";
import { AvatarCnt } from "../AvatarDropdown/styled";
import { NotificationsPopup } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import { Flex } from "../ContactPanel/styled";

interface IProps {
  open: boolean;
  setOpen: (v: boolean) => void;
}

const NotificationsDropdown: FC<IProps> = ({ open, setOpen }) => {
  return open ? (
    <>
      <NotificationsPopup>
        <div className="header">
          <span>Notifications</span>
          <span>
            Mark all as read
            <FontAwesomeIcon icon={faCheck} />
          </span>
        </div>
        <div className="list">
          <div className="notify active">
            <div className="round"></div>
            <div>
              <p>
                <b>James Munster</b> has accepted your invitation to join your
                team.
              </p>
              <span>Yesterday at 2:13 PM</span>
            </div>
          </div>
          <div className="notify active">
            <div className="round"></div>
            <div>
              <p>
                <b>James Munster</b> has accepted your invitation to join your
                team.
              </p>
              <Flex style={{ marginTop: "14px" }}>
                <Button title="Send reminder" green small />
                <Button title="Dismiss" light small />
              </Flex>
            </div>
          </div>
          <div className="notify">
            <div className="round"></div>
            <div>
              <p>
                <b>James Munster</b> has accepted your invitation to join your
                team.
              </p>
            </div>
          </div>
        </div>
      </NotificationsPopup>
      <AvatarCnt onClick={() => setOpen(false)} />
    </>
  ) : null;
};

export default NotificationsDropdown;

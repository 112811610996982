import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  PathValue,
} from "react-hook-form";
import Input, { InputProps } from "../components/Input";

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, "render">,
    InputProps {
  name: TName;
  defaultValue?: PathValue<TFieldValues, TName> | undefined;
}

const FormInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  defaultValue,
  disabled,
  rules,
  shouldUnregister,
  ...textInputProps
}: Props<TFieldValues, TName>) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      disabled={disabled}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field }) => <Input {...textInputProps} {...field} />}
    />
  );
};

export default FormInput;

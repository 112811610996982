import { ButtonBox, ButtonContainer } from "./styled";
import { FC } from "react";
import Plus from "../../assets/images/plus.png";
import Spinner from "../Spinner";

type Props = {
  title: string;
  tight?: boolean;
  green?: boolean;
  plus?: boolean;
  onClick?: () => void;
  fullWidth?: boolean;
  rounded?: boolean;
  small?: boolean;
  light?: boolean;
  disabled?: boolean;
  fontFamily?: "Poppins" | "Inter";
  fontWeight?: number;
  isLoading?: boolean;
  classNameWrapper?: string;
  className?: string;
  red?: boolean;
  width?: string;
};

const Button: FC<Props> = ({
  title,
  green = false,
  tight,
  plus,
  onClick,
  fullWidth,
  rounded,
  light,
  small,
  disabled,
  fontFamily,
  fontWeight,
  isLoading,
  classNameWrapper,
  className,
  red,
  width,
}) => {
  return (
    <ButtonContainer
      className={classNameWrapper}
      $small={!!small}
      $fullWidth={!!fullWidth}
    >
      <ButtonBox
        $fontWeight={fontWeight}
        $fontFamily={fontFamily}
        $rounded={!!rounded}
        $fullWidth={!!fullWidth}
        $green={!!green}
        $red={red}
        $tight={!!tight}
        $small={!!small}
        $light={!!light}
        onClick={onClick}
        disabled={!!disabled}
        className={className}
        $width={width}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {plus && <img src={Plus} alt="plus" />}
            {title}
          </>
        )}
      </ButtonBox>
    </ButtonContainer>
  );
};

export default Button;

import CheckImg from "../../assets/images/check.png";
import { ModalCnt } from "./styled";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import XMark from "../../assets/images/close.png";

interface IProps {
  onClose: () => void;
  isOpen: boolean;
  title: string;
}

const Modal: FC<IProps> = ({ isOpen, onClose, title }) => {
  const { t } = useTranslation();

  if (!isOpen) {
    return null;
  }

  return (
    <ModalCnt>
      <div onClick={onClose}></div>
      <div className="modalBox">
        <img src={XMark} alt="x-mark" className="closeBtn" onClick={onClose} />
        <img className="sendIcon" src={CheckImg} alt="success" />
        <p className="message">{title}</p>
        <CloseButton onClick={onClose}>{t("dashboard.Close")}</CloseButton>
      </div>
    </ModalCnt>
  );
};

export default Modal;

const CloseButton = styled.button`
  width: 252px;
  height: 40px;
  border-radius: 8px;
  background-color: #5080f4;
  box-shadow:
    0px 2px 5px 0px rgba(60, 66, 87, 0.08),
    0px 1px 1px 0px rgba(0, 0, 0, 0.12);
  color: #fff;
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 16px;
  margin-bottom: 20px;
  border: none;
  cursor: pointer;
`;

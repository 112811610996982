import { styled } from "styled-components";
import "./index.css";

interface iSpin {
  register?: boolean;
  isSpinnerCentered?: boolean;
}

const Spinner = ({ register, isSpinnerCentered }: iSpin) => {
  return isSpinnerCentered ? (
    <Wrapper>
      <div className={`spinnerLoader fullscreen`}></div>
    </Wrapper>
  ) : (
    <Loader className={`${register ? "register" : ""}`}></Loader>
  );
};

export default Spinner;

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 1s ease-in-out;
  &:hover {
    opacity: 1;
  }
`;

const Loader = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #fff;
    animation: prixClipFix 2s linear infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes prixClipFix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }
`;

import styled from 'styled-components'
import { Media } from '../../Contstants'
 
export const Container = styled.div`
  width: 96%;
  max-width: 1820px;
  margin: 0px auto;

  @media (max-width: ${Media.mobile}) {
    width: 100%;
    padding: 0px 5px;
    overflow: hidden;
  }
`

export const LoginLaoutContainer = styled.div`
  padding: 40px;
  background-color: #F4F6F8;

  @media (max-width: ${Media.mobile}) {
    background-color: #fff;
    padding: 20px;
  }
  
  img {
    margin: 0 auto;
    display: block;
  }
`
export const Colors = {
  brand: "#142A5E",
  secondary: "#A7D8E3",
  dark: "#123038",
  white: "#fefefe",
  textDark: "#4E6276",
  green: "#3A9CAD",
  lightGreen: "#65C68C",
  red: "#EE505A",
  newBlue: "#5080F4",
};

export const Distances = {
  lg: "30px",
  md: "20px",
  sm: "10px",
};

export const Media = {
  mobile: "768px",
  tablet: "992px",
};

export const BorderRadius = "20px";

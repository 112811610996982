import { Skeleton } from "@mui/material";

const ListSkeleton = (props: { listsToRender: number }) => {
  return (
    <>
      {Array(props.listsToRender)
        .fill(1)
        .map((card, index) => (
          <Skeleton
            key={index}
            variant="text"
            sx={{ bgcolor: "#F4F6F8" }}
            height={50}
          />
        ))}
    </>
  );
};

export default ListSkeleton;

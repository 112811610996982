import { UseMutationOptions, useMutation } from "react-query";
import axiosInstance from "../../utils/axios";

interface BaseMutationArguments {
  clientId: string;
  country: string;
}

type MutationArguments = BaseMutationArguments[];

function mutationFn(args: MutationArguments) {
  return axiosInstance.post(`/account/client/consents`, args);
}

export function useAddClientConceptMutation(
  options?: Omit<
    UseMutationOptions<unknown, unknown, MutationArguments>,
    "mutationFn"
  >,
) {
  return useMutation(mutationFn, options);
}

import styled from "styled-components";
import { Colors, Media } from "../../Contstants";

type Props = {
  $large?: boolean;
  $hasError?: boolean;
  $type?: string;
  $hasIcon?: boolean;
  $disableMarginRight?: boolean;
};

export const InputContainer = styled.div<Props>`
  position: relative;
  margin-right: ${(props) => (props.$disableMarginRight ? "0" : "16px")};
  display: "block";
  align-items: center;

  .parentCnt {
    flex: unset !important;
    display: flex;
    align-items: center;

    .textColor {
      margin-right: 49px;
      color: ${Colors.textDark};
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      user-select: none;
    }
  }

  .parentCnt input:checked ~ .textColor {
    color: #5080f4;
  }

  @media (max-width: ${Media.mobile}) {
    margin-right: 0;
    margin-bottom: 8px;
  }

  label {
    display: block;
    color: ${Colors.brand};
    font-size: 12px;
    position: relative;
    margin-bottom: "8px";
    font-family: "Inter";
  }

  svg {
    position: absolute;
    right: 15px;
    bottom: 15px;
    font-size: 17px;
    color: ${Colors.textDark};
    cursor: pointer;
  }

  .error {
    font-size: 12px;
    color: #fa5d5d;
    margin: 3px 0 0 0;
    position: absolute;
    left: 16px;
    top: 80px;

    &.error-small {
      top: 62px;
    }
  }

  .icon-container {
    position: absolute;
    width: ${(props) => (props.$large || props.$hasIcon ? "48px" : "18px")};
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      object-fit: contain;
      overflow: hidden;
      width: 18px;

      @media (max-width: ${Media.mobile}) {
        display: none;
        width: 14px;
        height: 16px;
      }
    }
  }

  img.lock__icon {
    display: block;

    @media (max-width: ${Media.mobile}) {
      top: 14px;
    }
  }

  input {
    height: ${(props) => (props.$large ? "60px" : "auto")};
    border-radius: ${(props) => (props.$large ? "32px" : "4px")};
    border: 1px solid ${(props) => (props.$hasError ? "red" : "#d0d5dd")};
    min-width: ${(props) => (props.$large ? "360px" : "20px")};
    font-size: 16px;
    font-family: Inter;
    padding-left: ${(props) =>
      props.$large || props.$hasIcon ? "48px" : "18px"};
    color: ${Colors.textDark};
    caret-color: #5080f4;
    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    @media (max-width: ${Media.mobile}) {
      width: 100%;
      max-width: 100%;
      height: ${(props) => (props.$large ? "44px" : "auto")};
      text-align: left;
      padding-left: ${(props) => (props.$large ? "38px" : "10px")};
      font-size: 14px;
      padding-right: 12px;
      min-width: unset;
    }

    &:focus {
      outline: 1px solid ${Colors.newBlue};
    }
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #999999;
    font-size: 14px;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #999999;
    font-size: 14px;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #999999;
  }
`;

export const Required = styled.span`
  color: #f27c83;
  font-size: 18px;
  padding-left: 6px;
  position: absolute;
  top: -1px;
`;

export const CustomSelect = styled.select`
  height: 48px;
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #fff;
  min-width: 40px;
  width: 100%;
  font-size: 16px;
  font-family: Inter;
  padding-left: 18px;
  color: ${Colors.textDark};
  caret-color: #5080f4;
  display: inline-block;
  padding-right: 8px;

  @media (max-width: ${Media.mobile}) {
    width: 100%;
    max-width: 100%;
    // height: 32px;
    text-align: left;
    padding-left: 8px;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 12px;
  }
`;

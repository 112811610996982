import { FC } from "react";
import { AvatarCnt } from "../AvatarDropdown/styled";
import { NotificationsPopup } from "./styled";
// import People from "../../assets/images/people.svg";

interface IProps {
  open: boolean;
  setOpen: (v: boolean) => void;
}

const LandingPagesDropdown: FC<IProps> = ({ open, setOpen }) => {
  const one4dmdurl = process.env.REACT_APP_ONE4DMD_URL || "";

  return open ? (
    <>
      <NotificationsPopup>
        {/* <div className="header">
          <span>Switch to</span>
        </div> */}
        {/* <div className="list"> */}
        {/* <p>
            <img src={People} alt="NMD" />
            NMD Connect (coming soon)
          </p> */}
        {/* </div> */}
        <div>
          <p className="small">Find out more about...</p>
          <p className="big">
            <a href={one4dmdurl} target="_blank" rel="noopener noreferrer">
              ONE4DMD
            </a>
          </p>
        </div>
      </NotificationsPopup>
      <AvatarCnt style={{ zIndex: 8 }} onClick={() => setOpen(false)} />
    </>
  ) : null;
};

export default LandingPagesDropdown;

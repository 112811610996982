import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputContainer, Required } from "./styled";
import { forwardRef, useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@mui/material";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface InputProps {
  label?: string;
  placeholder?: string;
  name: string;
  large?: boolean;
  required?: boolean;
  type?: "text" | "number" | "email" | "date" | "select" | "password";
  value?: string;
  disabled?: boolean;
  hasError?: boolean;
  onChange?: (e: string) => void;
  errorText?: string;
  defaultValue?: string;
  readOnly?: boolean;
  disableMarginRight?: boolean;
  classNameContainer?: string;
  disableCopy?: boolean;
  disablePaste?: boolean;
}

const Input = forwardRef<React.ComponentRef<typeof TextField>, InputProps>(
  (
    {
      placeholder,
      label,
      name,
      hasError,
      large,
      required,
      type,
      value,
      disabled,
      errorText,
      onChange,
      defaultValue,
      readOnly,
      disableMarginRight,
      classNameContainer,
      disableCopy,
      disablePaste,
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const toggleShowPassword = () => {
      setShowPassword((prev) => !prev);
    };

    return (
      <InputContainer
        className={classNameContainer}
        $type={type}
        $large={large}
        $hasError={hasError}
        $disableMarginRight={disableMarginRight}
      >
        <Label label={label} required={required} name={name} />

        <PasswordIcon
          onClick={toggleShowPassword}
          icon={showPassword ? faEyeSlash : faEye}
          enabled={type === "password"}
        />

        <TextField
          ref={ref}
          inputProps={{
            readOnly,
            onCopy: disableCopy ? (e) => e.preventDefault() : undefined,
            onPaste: disablePaste ? (e) => e.preventDefault() : undefined,
          }}
          disabled={disabled}
          onChange={(e) => onChange?.(e.target.value)}
          defaultValue={defaultValue}
          value={value}
          id={"input-" + name}
          name={name}
          placeholder={placeholder}
          type={showPassword ? "text" : type}
          style={{
            width: "100%",
            backgroundColor: `${disabled || readOnly ? "#f3f3f3" : "#ffffff"}`,
            pointerEvents: `${readOnly ? "none" : "all"}`,
          }}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#4a4a4a",
            },
          }}
        />

        <ErrorMessage errorText={errorText} label={label} />
      </InputContainer>
    );
  },
);

export default Input;

const Label = (props: { label?: string; required?: boolean; name: string }) => {
  if (!props.label) {
    return null;
  }

  return (
    <label htmlFor={"input-" + props.name}>
      {props.label}
      {props.required && <Required>*</Required>}
    </label>
  );
};

const PasswordIcon = (props: {
  onClick: () => void;
  icon: IconProp;
  enabled?: boolean;
}) => {
  if (!props.enabled) {
    return null;
  }

  return <FontAwesomeIcon onClick={props.onClick} icon={props.icon} />;
};

const ErrorMessage = (props: { label?: string; errorText?: string }) => {
  if (!props.errorText) {
    return null;
  }

  return (
    <p className={props.label ? "error" : "error error-small"}>
      {props.errorText}
    </p>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from "react";
import { Flex, AvatarModal, AvatarCnt } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faArrowRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { styled } from "styled-components";
interface IProps {
  open: boolean;
  name: string;
  email: string;
  setOpen: (open: boolean) => void;
  onLogout: () => void;
  isLoading: boolean;
}

const AvatarDropdown: FC<IProps> = ({
  open,
  name,
  email,
  setOpen,
  onLogout,
  isLoading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return open ? (
    <>
      <AvatarModal className="avatarModal">
        <div className="userName">{name}</div>
        <div className="email">{email}</div>
        <div className="border"></div>
        <Flex label="settings" onClick={() => navigate("/profile")}>
          <FontAwesomeIcon style={{ marginRight: "16px" }} icon={faGear} />
          <div>{t("header.settings")}</div>
        </Flex>
        {isLoading ? (
          <LoaderContainer>
            <CircularProgress
              color="primary"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </LoaderContainer>
        ) : (
          <Flex label="signOut">
            <div className="button" onClick={onLogout}>
              <FontAwesomeIcon
                style={{ marginRight: "16px" }}
                icon={faArrowRightFromBracket}
              />
              <div>{t("header.sign_out")}</div>
            </div>
          </Flex>
        )}
      </AvatarModal>
      <AvatarCnt onClick={() => setOpen(false)} />
    </>
  ) : null;
};

export default AvatarDropdown;

const LoaderContainer = styled.div`
  padding: 0 22px 15px 22px;
`;

import { Link } from "react-router-dom";
import {
  HeaderMenu,
  HeaderStyled,
  LogoImg,
  Navs,
  Notifications,
  Profile,
  ProfileContainer,
} from "./styled";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import AvatarDropdown from "../AvatarDropdown";
import NotificationsDropdown from "../Notifications";
import LandingPagesDropdown from "../LandingPages";
import { Colors } from "../../Contstants";
import keycloakInstance from "../../Keycloak";
import { useUser } from "../../hooks/queries";

const one4educateImg =
  process.env.REACT_APP_AWS_ONE4_BASE_URL + "one4educate.png";

const Header = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showLanding, setShowLanding] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useUser();
  const isSignedIn = Boolean(user);

  const toggleProfileDropdown = (toggle: boolean) => {
    return () => setOpen(toggle);
  };

  const toggleLandingDropDown = (toggle: boolean) => {
    return () => setShowLanding(toggle);
  };

  const toggleNotificationPanel = (toggle: boolean) => {
    return () => setShowNotification(toggle);
  };

  const logoutHandler = async () => {
    const one4DmdUrl = process.env.REACT_APP_ONE4DMD_URL;
    try {
      setIsLoading(true);
      const url = keycloakInstance.createLogoutUrl({ redirectUri: one4DmdUrl });
      window.location.href = url;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <HeaderStyled>
      <div style={{ position: "relative" }}>
        {isSignedIn ? (
          <>
            <HeaderMenu onClick={toggleLandingDropDown(true)}>
              <Icon
                icon="fontisto:nav-icon-grid"
                fontSize={24}
                color="#93A1AF"
              />
            </HeaderMenu>

            <LandingPagesDropdown
              open={showLanding}
              setOpen={toggleLandingDropDown(false)}
            />
          </>
        ) : null}
      </div>
      <Link to={"/"}>
        <LogoImg src={one4educateImg} alt="one4educate" />
      </Link>
      <Navs>
        <ProfileContainer>
          <Link to={"/contact"} className="contact">
            {t("header.contact")}
          </Link>

          <Notifications onClick={toggleNotificationPanel(true)}>
            <span>1</span>
            <Icon
              icon="ion:notifications-outline"
              fontSize={28}
              color={Colors.textDark}
            />
          </Notifications>

          {isSignedIn ? (
            <>
              <Profile
                $imgSrc={user?.avatar}
                onClick={toggleProfileDropdown(true)}
              >
                {user?.avatar?.includes("__dummy__")
                  ? `${user?.name[0]}${user?.surname[0]}`
                  : ""}
                <span className="active" />
              </Profile>

              <NotificationsDropdown
                open={showNotification}
                setOpen={toggleNotificationPanel(false)}
              />

              <AvatarDropdown
                isLoading={isLoading}
                setOpen={setOpen}
                onLogout={logoutHandler}
                name={user?.name + " " + user?.surname}
                email={user?.email ?? ""}
                open={open}
              />
            </>
          ) : (
            <Link to="/login" className="btn active">
              {t("header.signIn")}
            </Link>
          )}
        </ProfileContainer>
      </Navs>
    </HeaderStyled>
  );
};

export default Header;

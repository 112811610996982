import Keycloak, { KeycloakInitOptions } from "keycloak-js";

export const initOptions: KeycloakInitOptions = {
  onLoad: "check-sso",
  checkLoginIframe: false,
};

const keycloakInstance = new Keycloak({
  clientId: "portal-web",
  url: `${process.env.REACT_APP_KEYCLOACK_URL}`,
  realm: "portal",
});

export default keycloakInstance;

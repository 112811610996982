import { styled } from "styled-components";
import { Colors, Media } from "../../Contstants";

export const NotificationsPopup = styled.div`
  width: 380px;
  border-radius: 16px;
  border: 1px solid #dee3e9;
  background: #fff;
  box-shadow: 0px 3px 5px 0px rgba(154, 154, 154, 0.25);
  position: absolute;
  top: 50px;
  left: -200px;
  z-index: 9;
  overflow: hidden;

  @media (max-width: ${Media.mobile}) {
    width: 90vw;
    right: 5px;
    left: auto;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 16px 18px;

    span:first-child {
      color: #051123;
      font-family: Roboto;
      font-size: 14px;
    }

    span:last-child {
      color: ${Colors.textDark};
      font-family: Roboto;
      font-size: 14px;
      cursor: pointer;

      svg {
        border: 1px solid ${Colors.textDark};
        border-radius: 50%;
        padding: 3px 4px;
        font-size: 9px;
        margin-left: 6px;
        position: relative;
        top: 3px;
      }
    }
  }

  .list {
    max-height: 280px;
    overflow: auto;

    .notify {
      display: flex;
      padding: 16px 18px;
      border-left: 3px solid #fff;
      border-top: 1px solid #ececec;

      &.active {
        border-left-color: #5080f4;
      }

      .round {
        width: 25px;
        height: 25px;
        background: #d9d9d9;
        border-radius: 50%;
        margin-right: 12px;
        margin-top: 4px;
      }

      div:not(.round) {
        flex: 1;
      }

      p {
        margin: 0 0 4px 0;
        font-size: 14px;
        color: rgba(5, 17, 35, 0.5);
        line-height: 17px;

        b {
          color: #051123;
        }
      }

      span {
        color: #bcc8d3;
        font-family: Roboto;
        font-size: 12px;
      }
    }
  }
`;

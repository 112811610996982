import styled from "styled-components";
import { Colors, Media } from "../../Contstants";

interface iFlex {
  $gap?: string;
  $mb?: string;
  $shrink?: boolean;
}

export const ContactCnt = styled.div`
  display: flex;
  margin: 20px;
  min-height: calc(100vh - 219px);
  align-items: center;
  gap: 30px 70px;
  flex-wrap: wrap;
  justify-content: center;

  .buttonCnt {
    display: flex;
    justify-content: flex-end;
  }

  button {
    padding: 15px 48px;
    border-radius: 5px;
    align-items: flex-end;
  }

  @media (max-width: ${Media.mobile}) {
    display: block;
    height: auto;

    & > div {
      width: 100%;
      height: auto;
      min-height: 300px;
      margin-bottom: 20px;
    }
  }
`;

export const ImgWrapper = styled.div`
  /* width: 537px; */
  height: 561px;
  flex-shrink: 0;
  border-radius: 11px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.02) 0, rgba(0, 0, 0, 0.02)),
    linear-gradient(
      125deg,
      rgba(80, 128, 244, 0.22) 2.94%,
      rgba(162, 166, 237, 0.2) 48.63%,
      hsla(43, 88%, 94%, 0.22) 79.07%
    );
  padding: 30px 40px;

  h1 {
    color: #142a5e;
    font-family: Inter;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 0;
  }

  h3 {
    color: #4e6276;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 50px;
    line-height: 26px;
  }
  h2 {
    font-size: 16px;
    font-weight: 700;
    font-family: Inter;
    line-height: 1.75rem;
    font-style: normal;
    color: #142a5e;
  }

  .flex {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 15px;

    svg {
      color: #142a5e;
      width: 20px;
      height: 20px;
    }

    .contactText {
      color: #142a5e;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;

export const Flex = styled.div<iFlex>`
  display: flex;
  align-items: center;
  gap: ${(p) => p.$gap};
  margin-bottom: ${(p) => p.$mb};
  position: relative;
  & div:nth-child(1),
  & div:nth-child(2) {
    flex: ${(p) => (!p.$shrink ? "1" : "")};
    margin: 0;
  }

  .errorTxt {
    font-size: 12px;
    color: #fa5d5d;
    margin: 3px 0 0 0;
    position: absolute;
    left: 16px;
    top: 22px;
    @media (max-width: ${Media.mobile}) {
      top: 210px;
    }
  }

  @media (max-width: ${Media.mobile}) {
    display: block;
    margin-bottom: 0;
    & div:nth-child(1),
    & div:nth-child(2),
    & > div {
      margin-bottom: 8px;
    }
    &.checkboxFlex {
      & div {
        /* margin-bottom: 20px; */
      }
    }
  }
`;
export const DetailsCnt = styled.div`
  max-width: 642px;
  width: 100%;

  textarea {
    height: 129px;
    width: 100%;
    padding: 12px 16px;
    resize: none;
    border-radius: 4px;
    border: 1px solid #ececec;
    background: #fff;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 20px;
  }

  textarea.error {
    border: 1px solid red;
  }

  textarea::placeholder {
    color: #b7b7b7;
    font-size: 12px;
    font-weight: 400;
  }

  textarea:focus {
    outline: 1px solid #5080f4;
  }

  @media (max-width: ${Media.mobile}) {
    max-width: unset;
    .error {
      top: 59px;
    }
  }
`;

export const SelectHeader = styled.div<{ color?: string }>`
  color: ${(p) => p.color ?? "#5080f4"};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
`;
export const Label = styled.div`
  color: ${Colors.brand};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ModalCnt = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;

  & > div:nth-child(1) {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
  }
  .modalBox {
    position: fixed;
    width: 370px;
    height: 200px;
    background-color: #fff;
    border-radius: 8px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .closeBtn {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      width: 20px;
      height: 20px;
    }

    .sendIcon {
      width: 55px;
      height: 55px;
      display: block;
      margin: 50px auto;
      color: #65c68c;
    }
  }

  .message {
    text-align: center;
    font-size: 18px;
    margin-top: 25px;
    color: ${Colors.textDark};
    margin-top: -25px;
  }

  @media (max-width: ${Media.mobile}) {
    .message {
      font-size: 16px;
    }
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${Media.mobile}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

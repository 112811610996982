import styled from "styled-components";
import { Colors, Media } from "../../Contstants";

interface Iflex {
  label: "settings" | "signOut";
}

export const AvatarModal = styled.div`
  position: absolute;
  width: 270px;
  border-radius: 16px;
  background-color: #fff;
  top: 45px;
  right: 0;
  z-index: 5;
  border: 1px solid #dee3e9;

  @media (max-width: ${Media.mobile}) {
    text-align: left;
  }

  .userName {
    color: #051123;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 0 22px;
    margin-top: 16px;
  }

  .email {
    color: #bcc8d3;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 0 22px 16px 22px;
  }

  .border {
    width: 100%;
    height: 1px;
    background: #ececec;
  }

  .button {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

export const Flex = styled.div<Iflex>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${(p) => (p.label === "settings" ? "22px" : "0 22px 22px 22px")};
  svg {
    margin-right: 16px;
    color: ${(p: Iflex) =>
      p.label === "settings" ? Colors.textDark : "#F27C83"};
  }
  div {
    color: ${(p) => (p.label === "settings" ? "#051123" : "#F27C83")};
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const AvatarCnt = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  z-index: 4;
`;
